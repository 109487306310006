@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Potta+One&display=swap');

        *{
            /* font-family: 'Lato', sans-serif; */
            font-family: 'Noto Sans', sans-serif;
            box-sizing: border-box;
        }


        .main-container{
            width: 95vw;
            margin: 1rem auto;
            border-radius: 12px;
            padding: 1rem 0.5rem;
            display: grid;
            grid-template-columns: 2fr 3fr;
            column-gap: 2rem;
            background: white;
            filter: drop-shadow(0.5rem 0.5rem 4px #cdcdcd);
        }

        .class-adder{
            
            width: 95vw;
            margin: 1rem auto;
            background: white;
            border-radius: 12px;
            padding: 0.5rem;
            filter: drop-shadow(0.5rem 0.5rem 4px #cdcdcd);
        }

        .title{
            font-size: 1.8rem;
            font-weight: 700;
        }
        
        .subtitle{
            font-size: 1.5rem;
            font-weight: 400;
            color: #403651;
        }

        .right-container{
            /* width: 98vw; */
            margin: 0 auto;
        }

        .top-container{
            width: 100%;
        }

        .flex-d{
            display: flex;
            /* justify-content: center; */
            align-items: center;
        }

        .flex-d.center{
            justify-content: center;
            align-items: center;
        }

        .flex-d.end{
            justify-content: end;
            align-items: center;
        }

        .form-field{
            margin: 1.2rem 0px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .form-field-row{
            width: 48%;
            display: flex;
            flex-direction: column;
            /* background: red; */
            justify-content: space-between;
        }

        .row{
            display: flex;
            justify-content: space-between;
        }

        label{
            margin: 0.6rem 0;
            font-size: 1.2rem;
            font-weight: 500;
        }

        input, select{
            font-size: 1.2rem;
            font-weight: 400;
            padding: 0.5rem 0.8rem;
            border-radius: 12px;
            border: 3px solid #cdcdcd;
            transition: all 1s ease-in-out;
        }

        input:focus {
            outline: none !important;
            border:3px solid #cdcdcd;
            box-shadow: 0 0 10px #719ECE;
        }

        input.error_input{        
            border: 2px solid red;
            transition: all 1s ease-in-out;
        }

        .custom-btn{
            margin: 2rem 0px;
            width: 80%;
            border: none;
            padding: 1rem 1rem;
            font-size: 1.2rem;
            font-weight: 700;
            border-radius: 12px;
            background: #4754e2;
            color: white;
        }
        
        .custom-btn:hover{
            box-shadow: 0 0 10px #719ECE;
        }

        .error{
          color: red;
          font-size: 0.8rem;
        }

        .custom_table{
          width: 96vw;
          margin: 0 auto;
          /* text-align: end; */
        }

        th{
            
            background-color: #c4baba;
            border-left: 0.5px solid white;
            padding: 4px;
        }

        td {
            border-left: 0.5px solid white;
            text-align: left;
            padding: 8px;
        }

        tr:nth-child(even) {
        background-color: #c4baba;
        }


        .navbar{
            width: calc(100vw-4px);
            overflow: hidden;
            background: white;
            padding: 4px 4vw;
            filter: drop-shadow(0px 4px 4px #c0b0dd);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }


        .router-link{
            text-decoration: none;
            margin-left: 1em;
            transition: all 1s ease-in-out;
        }

        .router-link:hover{
            color: #403651;
            transition: all 1s ease-in-out;
        }

        .btn{
            border: none;
            background: black;
            border-radius: 4px;
            color: white;
            padding: 0.5rem;
            margin-left: 1em;
            cursor: pointer;
        }

        .login-form{
            max-width: 575px;
            margin: 0 auto;
        }

        .topbar{
            display: flex; 
            flex-direction: row;
            justify-content: space-between;
            width: 95vw;
            /* background: red; */
            margin: 1rem auto;
        }

        .custom-exporter{
            background: #4754e2;
            padding: 1.2rem;
            border-radius: 8px;
            border: none;
            margin-left: 0.2rem;
            color: white;
            text-align: end;
            text-decoration: none;
        }

        .search-bar{
            width: 40%;
        }

        .logo{
            display: block;
            text-decoration: none;
            font-weight: bold;
            font-size: 1.5rem;
            padding: 3vh 0;
            color: black;
        }

        .input-field{    
            width: 100%;
            padding-right: 50px;
        }

        .icon-tail {
            position: absolute;
            color: gray;
            margin-left: -50px;
            padding: 0.72rem;
            vertical-align:middle;
            text-align: center;
            text-align: center;
            font-weight: bold;
            cursor: pointer;
        }


        .sort-by-date{
            margin-right: 0.5rem;
            /* font-size: 1.2rem; */
            font-weight: 500;
            cursor: pointer;
        }



        .audioshelf{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            row-gap: 20px;
            column-gap: 10px;
            max-width: 95vw;
            margin: 0px auto;

        }

        .audio-card{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            padding: 0.6rem 1rem;
            background: white;
            filter: drop-shadow(0.25rem 0.25rem 4px #cdcdcd);
        }

        
@media screen and (max-width: 1020px) {

             .audioshelf{
            /* display: grid; */
            grid-template-columns: repeat(2,1fr);
        }

        .main-container{
            grid-template-columns: 1fr;
            column-gap: 0;
        }

        .flex-d.center{
            display: none;
        }      
}


@media screen and (max-width: 768px) {
        .logo{
            /* display: none; */
            
            font-size: 1.2rem;
        }
        .navbar{
            justify-content: space-between;
        }
        .row{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .login-form{
            max-width: 90vw;
        }

        .form-field{
            width: 100%;
            margin: 0.5rem 0px;
        }

 

        .form-field-row{
            margin: 0.5rem 0px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
       .custom-btn{
            margin: 1rem 0px;
            width: 100%;
            padding: 0.6rem 1rem;
        }

        .title{
            font-size: 1.2rem;
        }
        
        .subtitle{
            font-size: 0.8rem;
        }

                label{
            font-size: 0.9rem;
        }

        input{
            font-size: 0.9rem;
        }

        .custom_table{
            overflow-x: scroll;
        }

             .audioshelf{
            /* display: grid; */
            grid-template-columns: repeat(1,1fr);
            column-gap: 0;
        }


}